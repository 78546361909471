/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Gradient = styled.div`
  background: linear-gradient(302.67deg, #bd1622 28.88%, #f27400 80.97%);
  padding-bottom: 6rem;
`

const PartnersWrapper = styled.div`
  margin-top: -6rem;
`

const Partner = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  border-radius: 20px;

  & a {
    font-size: 0;
    &:hover {
      opacity: 0.75;
    }
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 40px;
    }
  }
`

const Image = styled(Plaatjie)`
  height: 81px;
  width: 182px;
`

const Letter = styled.span`
  font-family: ${({ theme }) => theme.font.family.secondary};
  opacity: 0.1;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 0.68;
  @media (min-width: 768px) {
    font-size: 200px;
  }
  @media (max-width: 767px) {
    font-size: 100px;
  }
`

interface PartnersProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Partners
}

const Partners: React.FC<PartnersProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <Gradient>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <Content
              content={fields.description}
              className="me-4 text-center"
            />
          </div>
        </div>
      </div>
    </Gradient>
    <div className="container">
      <PartnersWrapper className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            {fields.partners?.map((partner, index) => (
              <div className="col-md-4 mb-5" key={index}>
                <Partner className="px-4 pt-4 d-flex flex-column align-items-center justify-content-between h-100">
                  <a href={partner?.link?.url} target="_blank" rel="noreferrer">
                    {partner?.link?.title}
                    <Image image={partner?.image} alt="" className="mb-4" />
                  </a>
                  <ParseContent
                    content={partner?.description}
                    className="mb-5"
                  />
                  <Letter>{partner?.highlightedletter}</Letter>
                </Partner>
              </div>
            ))}
          </div>
        </div>
      </PartnersWrapper>
    </div>
  </section>
)

export default Partners
